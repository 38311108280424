.Container {
    margin: 10px;
    border-left: solid rgb(31, 118, 212) 5px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    font-family: 'Oxanium', sans-serif;
    
}

.Container h1 {
    font-family: 'Chakra Petch', sans-serif;
    margin-bottom: 10px;
}

.CardContainer {
    margin-top: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    align-items: stretch;
}

.accordion-button:not(.collapsed), .accordion-button:focus {
    color: black;
    background-color: white !important;
    box-shadow: none !important
}

.TaskCard p {
    border-top: solid lightgray 1px
}

.TaskButton {
    width: 100%;
}

.TaskButtonMiddle {
    margin: 0 3px 0 3px
}

.accordion-button {
    padding: 10px 15px 0 15px !important;
}

.OtherCards {
    border-top: solid black 1px;
    padding-top: 10px;
}

.accordion-body {
    padding: 0 15px 10px 15px;
}