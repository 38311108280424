.floating-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    z-index: 1000; 
  }

  .formField {
    margin: 5px 0 
  }

  .radio {
    margin: 5px 0;
    padding-left: 12px;
    border: solid #dee2e6 1px;
    border-radius: 6px;
  }