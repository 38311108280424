thead, tbody {
    text-align: center;
}

.Actions {
    text-align: end;
}

.middleButton {
    margin: 0 5px
}