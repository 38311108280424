.Home {
    margin: 10px;
    padding: 15px;
    border-left: solid rgb(31, 118, 212) 5px;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    font-family: 'Oxanium', sans-serif; 
}

.Home h1 {
    font-family: 'Chakra Petch', sans-serif;
}